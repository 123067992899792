import React from 'react';
import StudioLayout from "../../components/studio/studioLayout"
import Year from "../../components/studio/year"

const Year2018 = () => {

    const imageData = [
        {
            name: "p1",
            width: 420,
        },
        {
            name: "p2",
            width: 420,
        },
        {
            name: "p3",
            width: 420,
        },
        {
            name: "p4",
            width: 420,
        },
        {
            name: "p5",
            width: 587,
        },
        {
            name: "p6",
            width: 485,
        },
        {
            name: "p7",
            width: 384,
        },
        {
            name: "p8",
            width: 420,
        },
        {
            name: "p9",
            width: 353,
        },
        {
            name: "p10",
            width: 420,
        },
        {
            name: "p11",
            width: 420,
        },
        {
            name: "p12",
            width: 420,
        },
        {
            name: "p13",
            width: 420,
        },
        {
            name: "p14",
            width: 420,
        },
        {
            name: "p15",
            width: 420,
        },
        {
            name: "p16",
            width: 339,
        },
        {
            name: "p17",
            width: 426,
        },
        {
            name: "p18",
            width: 491,
        },
        {
            name: "p19",
            width: 420,
        },
        {
            name: "p20",
            width: 420,
        },
        {
            name: "p21",
            width: 336,
        },
        {
            name: "p22",
            width: 420,
        },
        {
            name: "p23",
            width: 494,
        },
        {
            name: "p24",
            width: 420,
        },
        {
            name: "p25",
            width: 610,
        },
        {
            name: "p26",
            width: 420,
        },
        {
            name: "p27",
            width: 420,
        },
        {
            name: "p28",
            width: 420,
        },
        {
            name: "p29",
            width: 464,
        },
        {
            name: "p30",
            width: 420,
        },
    ]

    return (
        <StudioLayout year={2018}>
            <Year year={18} imageData={imageData} />
        </StudioLayout>
    );
}

export default Year2018;